import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../App';
import './home.css';
import { parseISO, format } from 'date-fns';
import { FaPlaneDeparture , FaPlaneArrival } from "react-icons/fa";
import image1 from '../images/1.PNG'
import image2 from '../images/2.PNG'
import image3 from '../images/Nwap.PNG'



const Viewer = () => {
  const { role } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const fetchedIds = useRef(new Set());

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData(page);
    }, 10000); // Refresh every 10 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [page]);

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/ParliamentariansForm?SortBy=flightTime&IsDecsending=true&PageSize=100&IsExpired=false`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        
      });
     

      const flightData = response.data;
      if (flightData.length === 0) {
        setHasMore(false);
      }

      const dataArray = Array.isArray(flightData) ? flightData : [flightData];
      const uniqueData = dataArray.filter(item => !fetchedIds.current.has(item.id));

      uniqueData.forEach(item => fetchedIds.current.add(item.id));

      const convertedData = uniqueData.map(item => ({
        
        ...item,
        role: convertRole(item.role),
        departureOrArrival: convertDepartureOrArrival(item.departureOrArrival),
        priority: convertPriority(item.priority),
        statusType: convertStatusType(item.statusType),
        createdBy: item.createdBy.userName || 'Unknown',
        
      }));

      setData(prevData => [...prevData, ...convertedData]);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login'); // Redirect to login if unauthorized
      }
    }
    setLoading(false);
  };

  const convertRole = (value) => {
    switch (value) {
      case 0: return 'برلماني'; // Assuming 0 means parliamentarian
      case 1: return 'ضيف';
      case 2: return 'مستشار';
      case 3: return 'درجة خاصة'; // Assuming 1 means guest
      default: return '';
    }
  };

 

  const convertDepartureOrArrival = (value) => {
    const iconStyle = {
      marginLeft: '5px',  // Space between text and icon
      fontSize: '1.5em',    // Icon size relative to text
    };
  
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {value === 0 && (
          <>
            مغادرة
            <FaPlaneDeparture style={{ ...iconStyle, color: 'red' ,marginRight: '10px' }} />
          </>
        )}
        {value === 1 && (
          <>
            قدوم
            <FaPlaneArrival style={{ ...iconStyle, color: 'green' ,marginRight: '15px' }} />
          </>
        )}
      </div>
    );
  };

  const convertPriority = (value) => {
    switch (value) {
      case 0: return 'تصوير جوازات';
      case 1: return 'توقف تفتيش';
      case 2: return 'بدون توقف';
      default: return 'Unknown';
    }
  };

  const convertStatusType = (value) => {
    switch (value) {
      case 0: return 'انتظار';
      case 1: return 'جاء';
      case 2: return 'متأخر';
      case 3: return 'لم ياتي';
      default: return 'Unknown';
    }
  };

  const convertToLocalTime = (utcDateTimeString) => {
    if (utcDateTimeString) {
      const utcDate = parseISO(utcDateTimeString);
      return format(utcDate, 'yyyy-MM-dd HH:mm:ss');
    }
    return '';
  };


  const getRowColor = (flightTime) => {
    const currentTime = new Date();
    const flightDateTime = new Date(flightTime);
  
    if (isNaN(flightDateTime.getTime())) return ''; // Handle invalid dates
  
    const threeHoursBefore = new Date(flightDateTime.getTime() - 180 * 60 * 1000);
  
    if (currentTime > flightDateTime) {
      return 'red'; // Flight time has passed
    } else if (currentTime > threeHoursBefore) {
      return 'green'; // Within 3 hours before the flight time
    } else {
      return ''; // Default color (no color)
    }
  };

  
  const getCellClass = (value) => {
    switch (value) {
      case 'قدوم': // Arrival
        return 'arrival';
      case 'مغادرة': // Departure
        return 'departure';
      default:
        return '';
    }
  };
  


  

  const observerCallback = useCallback((entries) => {
    if (entries[0].isIntersecting && hasMore && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(observerCallback);
    if (lastElementRef.current) observer.current.observe(lastElementRef.current);
  }, [loading, observerCallback]);

  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading]);

  return (
    <div className="home-container">
      
      <div className='images-container'>
      <img src={image2} alt="Description of image" className="image1" />
    {/* Alternatively, if you want to use image2 */} 
    <img src={image3} alt="Description of image" className="image1" />
    </div> 
      <table className="home-table1">
        <thead>
          <tr>
            {/* <th>ID</th> */}
            
            <th> الاسم  </th>
            <th>الصفة</th>
            <th>عدد المرافقين</th>
            <th>الوجهة</th>
            
            <th>رقم الرحلة</th>
            <th>قدوم / مغادرة</th>
            <th>الاجراء</th>
            <th>الحالة</th>
            {/* <th>تاريخ الاضافة</th> */}
            <th>وقت الرحلة</th>
            <th>اسم المدخل</th> 
            <th>ملاحظات</th>
            
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} ref={index === data.length - 1 ? lastElementRef : null} >
              {/* <td>{item.id}</td> */}
             
              <td>{item.name}</td>
              <td>{item.role}</td>
              <td>{item.numOfEscorts}</td>
              
              <td>{item.destination}</td>
              
              <td>{item.flightNumber}</td>
              <td className={getCellClass(item.departureOrArrival)}>
                   {item.departureOrArrival}
                </td>
              <td>{item.priority}</td>
              <td>{item.statusType}</td>
              {/* <td>{convertToLocalTime(item.createdOn)}</td> */}
              <td style={{ color: getRowColor(item.flightTime) }}>
  {convertToLocalTime(item.flightTime)}
</td>
              <td>{item.createdBy}</td>
              <td>{item.approval}</td>
              
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <p className='loadingV'>Loading...</p>}
      {!hasMore && <p className='loadingV'>No more data to load</p>}
    </div>
  );
};

export default Viewer;
