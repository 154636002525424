import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './home.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FaEye , FaEyeSlash } from "react-icons/fa"; 

const Users = () => {
  const token = localStorage.getItem('token');
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({ username: '', name: '', password: '', userRole: 1 });
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const itemsPerPage = 1000;

  // Function to fetch user data
  const fetchData = () => {
    axios.get('/api/account', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      const formattedData = response.data.map(user => ({
        id: user.userName, // Assuming userName is unique and can be used as an ID
        username: user.userName,
        name: user.name,
        role: user.userRole,
      }));
      setTableData(formattedData);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  // Fetch user data on component mount
  useEffect(() => {
    fetchData();
  }, [token]);

  // Calculate the displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    // Prepare the payload based on the request type (POST or PUT)
    const payload = isEditing
      ? {
          username: formData.username,
          name: formData.name,
          newPassword: formData.password, // Use newPassword for PUT
          userRole: formData.userRole,
        }
      : {
          username: formData.username,
          name: formData.name,
          password: formData.password, // Use password for POST
          userRole: formData.userRole,
        };
  
    // Determine the endpoint and method based on whether we are editing or creating
    const endpoint = isEditing
      ? `/api/account/edit/${editUserId}`
      : '/api/account/register';
    const method = isEditing ? 'put' : 'post';
  
    axios({
      method: method,
      url: endpoint,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        fetchData(); // Refresh the data
        setFormData({ username: '', name: '', password: '', userRole: 1 });
        setShowForm(false);
        setIsEditing(false);
        setEditUserId(null);
        setErrorMessages([]);
      })
      .catch(error => {
        console.error('Error creating/updating user:', error);
        if (error.response && error.response.data) {
          try {
            const errors = typeof error.response.data === 'string' ? JSON.parse(error.response.data) : error.response.data;
            setErrorMessages(errors.map(err => err.description));
          } catch (err) {
            console.error('Error parsing error response:', err);
            setErrorMessages(['Unexpected error occurred.']);
          }
        } else {
          setErrorMessages(['Error creating/updating user.']);
        }
      });
  };
  
  // Handle row deletion
  const handleDelete = (username) => {
    axios.delete(`/api/account/remove/${username}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
      fetchData(); // Refresh the data
    })
    .catch(error => {
      console.error('Error deleting user:', error);
    });
  };

  // Handle form cancellation
  const handleCancel = () => {
    setFormData({ username: '', name: '', password: '', userRole: 1 });
    setShowForm(false);
    setIsEditing(false);
    setEditUserId(null);
    setErrorMessages([]);
  };

  const handleEdit = (user) => {
    setFormData({
      username: user.username,
      name: user.name,
      password: '', // Keep password empty unless the user wants to change it
      userRole: user.role === 'Admin' ? 2 : user.role === 'User' ? 1 : 0,
    });
    setEditUserId(user.id);
    setIsEditing(true);
    setShowForm(true);
  };

  // Handle opening the form
  const handleAddButtonClick = () => {
    setFormData({ username: '', name: '', password: '', userRole: 1 });
    setShowForm(true);
    setErrorMessages([]);
  };

  return (
    <div className="home">
      <h2>قائمة المستخدمين</h2>
      <div className="controls">
        <button onClick={handleAddButtonClick}>اضافة</button>
      </div>
      <table className='home-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Name</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.username}</td>
              <td>{row.name}</td>
              <td>{row.role}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(row)}>تعديل</button>
                <button className="delete-button" onClick={() => handleDelete(row.username)}>حذف</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Uncomment the pagination if needed */}
      {/* <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div> */}
      {showForm && (
        <div className="form-overlay">
          <div className="form-container2">
            <form onSubmit={handleFormSubmit}>
              <h2> اضافة حساب</h2>
              {errorMessages.length > 0 && (
                <div className="error-messages">
                  {errorMessages.map((message, index) => (
                    <div key={index} className="error-message" style={{ animationDelay: `${index * 0.5}s` }}>
                      {message}
                    </div>
                  ))}
                </div>
              )}
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleInputChange}
                required
              />
              <input
                type="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <select
                name="userRole"
                value={formData.userRole}
                onChange={handleInputChange}
                required
              >
                <option value={2}>Admin</option>
                <option value={1}>User</option>
                <option value={0}>View</option>
              </select>
              <div className="form-buttons">
                <button type="submit">اضافة</button>
                <button type="button" onClick={handleCancel}>الغاء</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
